export default defineStore('carts', () => {
    const { getUserData, userData } = useUser()

    const cachedCart = JSON.parse(localStorage.getItem(LocalStorageNameTypes.USER_ACTIVE_CART) ?? '{}')
    const activeCart = ref<string | null>(cachedCart?.id ?? null)
    const activeCartName = ref<string | null>(cachedCart?.name ?? null)
    const activeCartState = ref<string | null>(cachedCart?.cartState ?? null)

    const updateCachedCart = async (id: string | null, name: string | null, state: string | null) => {
        if (!userData.value.id) {
            await getUserData()
        }

        activeCart.value = id
        activeCartName.value = name
        activeCartState.value = state

        localStorage.setItem(
            LocalStorageNameTypes.USER_ACTIVE_CART,
            JSON.stringify({
                id: activeCart.value ?? '',
                userName: userData.value.id ?? '',
                name: activeCartName.value ?? '',
                cartState: activeCartState.value ?? '',
            })
        )
    }

    return {
        activeCart,
        activeCartName,
        activeCartState,
        updateCachedCart,
    }
})
